import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';

export const AboutUs = () => {
    const { t } = useTranslation();
    const [about, setAbout] = useState([]);
    const [generalData, setgeneralData] = useState([]);

    const [custormerCount, setcustormerCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const { user } = useAuth();


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImagePath(res.data['data']['path'])
                alert("yüklendi")
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    const aboutImage = async (e) => {

        if (imagePath != null) {
            about['imageUrl'] = "https://api.heryerdeingilizce.com/" + imagePath;
            generalData.aboutus[0] = about;

            try {
                const headerss = {
                    "accept": "*/*",
                    'Access-Control-Allow-Credentials': 'false',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
                await axios({
                    method: 'post',
                    url: "https://express.heryerdeingilizce.com/main",
                    data: JSON.stringify(generalData),
                    headers: headerss
                });

            }
            catch (err) {
                setMsg(err);
            }
            e.preventDefault();


        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
        window.location.reload();
    }

    const handlecustomerCount = async (e) => {
        const dataaa = {
            count: document.getElementById("musteriSayisi").value,
        }

        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'put',
                url: "https://express.heryerdeingilizce.com/postcustomercount",
                data: dataaa,
                headers: headerss
            }).then(response => {
                if (response.status === 200) {
                    alert("güncellendi")
                    costumer();
                    window.location.reload(true);

                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }


    const handleaboutus = async (e) => {
        about.title = document.getElementById("title").value;
        about.mainText = document.getElementById("mainText").value;
        about['subTexts'][0]['text'] = document.getElementById("1").value;
        about['subTexts'][1]['text'] = document.getElementById("2").value;
        about['subTexts'][2]['text'] = document.getElementById("3").value;
        generalData.aboutus[0] = about;
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            }).then(response => {
                getData();
                window.location.reload();

            });

        }
        catch (err) {
            setMsg(err);
        }
    }

    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setAbout(res.data['aboutus'][0]);
                setgeneralData(res.data)
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    const costumer = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getcustomercount",
                headers: headerss
            }).then(res => {
                setcustormerCount(res.data[0]['count'])
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        costumer();
        getData();
    }, []);


    return (
        <div className="row">
            {msg}
            <h2>{t('customercount')}</h2>
            <strong>Hızlı değiştirme yapmak istiyorsanız lütfen her değiştirme sonrası sayfayı yenileyiniz yada farklı bir sayfaya geçiş yapıp tekrar aynı sayfayı açınız.</strong>
            <form onSubmit={handlecustomerCount}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="musteriSayisi" class="form-label">{t('customercount')}</label>
                        <textarea class="form-control" id="musteriSayisi" defaultValue={custormerCount} required />
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div><br />
                <br />
            </form>

            <hr />

            <h2>
                {t('aboutussettings')}
            </h2>
            <form onSubmit={handleaboutus}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">
                            {t('aboutustitle')}
                        </label>
                        <textarea class="form-control" id="title" defaultValue={about.title} />
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-3">
                        <label for="mainText" class="form-label">
                            {t('aboutustext')}
                        </label>
                        <textarea class="form-control" id="mainText" defaultValue={about.mainText} />
                    </div>
                </div>


                {about.subTexts && about.subTexts.map((dt) => {
                    if (dt.id < 4) {
                        return (
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for={dt.id} class="form-label">{t('features')} {dt.id}</label>
                                    <textarea class="form-control" id={dt.id} defaultValue={dt.text} />
                                </div>
                            </div>
                        )
                    }
                })
                }
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
                <br />
            </form>
            <hr />
            {!loading && (
                <div className='row'>
                    <h6>{t('mainpagesectionimage')}</h6><br />
                    <div className='col-md-6'>
                        <span>454x548 boyutlarında</span>
                        <img src="/images/about.png" />
                    </div>
                    <div className='col-md-6'>
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={aboutImage}>
                                <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}
export default AboutUs;