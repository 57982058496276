import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import axios from "axios";

const Navbar = (props) => {
    const { t } = useTranslation()
    const { user } = useAuth();
    const [logo, setLogo] = useState('');
    useEffect(() => {
        (async () => {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setLogo(res.data['genel'][0]);
            }
            );

        })()
    }, [])
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid" style={{ backgroundColor: "white" }}>
                <a className="navbar-brand" href="/dashboard">
                    <img src={logo['logoUrl']} alt="logo" style={{ maxWidth: "220px", padding: "5%" }} class="img-fluid" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <form className="d-flex">
                        <a href="/profile">
                            <i
                                class="fa fa-user-circle"
                                style={{ fontSize: 40 }}
                                aria-hidden="true"
                            >
                            </i>
                            &nbsp;
                            <span>
                                <strong>{user.user['fullName']}</strong>
                                <br />
                                {user.role}
                            </span>
                        </a>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;