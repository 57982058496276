import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";


export const CreateReview = () => {
    const { t } = useTranslation();
    const [msg, setMsg] = useState([]);



    const handleReview = async (e) => {
        e.preventDefault();
        let form = document.getElementById("reviewform");
        const dataa = new FormData(form);
        let output = "";
        for (const entry of dataa) {
            output = `${output}${entry[1]}`;
        }
        const postData = {
            namesurname: document.getElementById("name").value,
            info: document.getElementById("description").value,
            message: document.getElementById("text").value,
            star: output,
            isactive: 1
        }
        const headerss = {
            "accept": "*/*",
            'Access-Control-Allow-Credentials': 'false',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
        await axios({
            method: 'post',
            url: "https://express.heryerdeingilizce.com/postreview",
            data: postData,
            headers: headerss
        }).then(response => {
            if (response.status === 201) {
                alert(response.data)
                window.location.reload();
            }
        });

    }

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            {msg}
            <div style={{ textAlign: "center" }}>
                <h1>{t("createreview")}</h1>
            </div>
            <form onSubmit={handleReview} id="reviewform">

                <div class="mb-3">
                    <label for="rating">{t("reviewRate")}</label><br />
                    <fieldset class="rating" id="rating">
                        <input type="radio" id="star5" value="5" name="rating" />
                        <label for="star5">5 stars</label>
                        <input type="radio" id="star4" name="rating" value="4" />
                        <label for="star4">4 stars</label>
                        <input type="radio" id="star3" name="rating" value="3" />
                        <label for="star3">3 stars</label>
                        <input type="radio" id="star2" name="rating" value="2" />
                        <label for="star2">2 stars</label>
                        <input type="radio" id="star1" name="rating" value="1" />
                        <label for="star1">1 star</label>
                    </fieldset>
                </div>
                <br /><br />
                <div class="mb-3">
                    <label for="text" class="form-label">{t("message")}</label>
                    <textarea class="form-control" id="text" required />
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">{t("namesurname")}</label>
                    <textarea class="form-control" id="name" required />
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label">{t("reviewdescription")}</label>
                    <textarea class="form-control" id="description" required />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>
        </div>
    )
}
export default CreateReview;