import React, { useState, useEffect } from "react";
import Datatable from "../Components/Datatable"
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';
import Swal from 'sweetalert2'
function SchoolTeacherList() {
    const [data, setData] = useState([]);
    const [deletedteachers, setdeletedteachers] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()

    const columns = React.useMemo(
        () => [
            {
                Header: t('id'),
                Footer: t('id'),
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: t('email'),
                Footer: t('email'),
                accessor: 'username', // accessor is the "key" in the data
            },
            {
                Header: t('namesurname'),
                Footer: t('namesurname'),
                accessor: 'fullName',
            },
            {
                Header: t('createdAt'),
                Footer: t('createdAt'),
                accessor: 'createdAt',
            },
            {
                Header: t('lastLogin'),
                Footer: t('lastLogin'),
                accessor: 'lastLogin',
            },
            {
                Header: t('deleteteacher'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t('deleteteacher')}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t('deleteteacher'),
            },
            {
                Header: t('editteacher'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('editteacher')}
                        </button>

                    </div>
                ),
                id: 'editteacher',
                Footer: t('editteacher'),
            },
        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/school/editteacher/" + row['id'];
    }

    const handleDelete = async (row) => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/DeleteLecturer?lecturerId=" + row['id'];
            await axios({
                method: 'Delete',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetLecturers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    data.push({
                                        "id": res.data['data'][i]['id'],
                                        "username": res.data['data'][i]['username'],
                                        "fullName": res.data['data'][i]['fullName'],
                                        "createdAt": res.data['data'][i]['createdAt'],
                                        "lastLogin": res.data['data'][i]['lastLogin'],
                                    })
                                }
                                else {
                                    deletedteachers.push({
                                        "id": res.data['data'][i]['id'],
                                        "username": res.data['data'][i]['username'],
                                        "fullName": res.data['data'][i]['fullName'],
                                        "createdAt": res.data['data'][i]['createdAt'],
                                        "lastLogin": res.data['data'][i]['lastLogin'],
                                    })
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);
    //TODO:İSDELETED EKLENECEK
    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("activeteachersList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("teachersList")} />
                    </div>
                )}
            </div>

            <br />
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("deletedteachersList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={deletedteachers} columns={columns} tableBaslik={t("teachersList")} />
                    </div>
                )}
            </div>
        </div>
    );
}
export default SchoolTeacherList;
