import React, { useState } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import axios from "axios";


function SchoolCreateTeacher() {
    const { user } = useAuth();
    const { t } = useTranslation()
    const [msg, setMsg] = useState([]);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/CreateLecturer";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: document.getElementById("username").value,
                    password: document.getElementById("password").value,
                    fullName: document.getElementById("namesurname").value,
                    roleId: 4,
                    schoolId: user.userDetail.schoolId,
                    isDeleted: false,
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);

                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }

    return (
        <div>            <h5>{msg}</h5>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t('createteacher')}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>

                        <div class="mb-3">
                            <label for="username" class="form-label">{t('email')}</label>
                            <textarea type="email" class="form-control" name="username" id="username" required />
                        </div>
                        <div class="mb-3">
                            <label for="namesurname" class="form-label">{t('namesurname')}</label>
                            <input class="form-control" name="namesurname" id="namesurname" required />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">{t('password')}</label>
                            <input type="password" class="form-control" name="password" id="password" required />
                        </div>


                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary"> {t('create')} </button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default SchoolCreateTeacher;
