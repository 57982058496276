import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';

export const SystemUsage = () => {
    const { t } = useTranslation();
    const [usage, setUsage] = useState([]);
    const [videoPath, setVideoPath] = useState(null);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const [generalData, setgeneralData] = useState([]);


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "video"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setVideoPath(res.data['data']['path'])
                alert("Yüklendi")
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handleVideo = async (e) => {
        const updateData = usage;
        let url = "https://api.heryerdeingilizce.com/" + videoPath;
        updateData.videoLink = url;
        generalData.usage[0] = updateData
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();
    }


    const systemusage = async (e) => {
        const newData = usage;
        newData.title = document.getElementById("title").value;
        newData.videoLink = usage.videoLink;
        newData.paragraphs[0]['text'] = document.getElementById("1").value;
        newData.paragraphs[1]['text'] = document.getElementById("2").value;
        newData.paragraphs[2]['text'] = document.getElementById("3").value;
        newData.paragraphs[3]['text'] = document.getElementById("4").value;

        generalData.usage[0] = newData
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();
    }




    const getData = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setUsage(res.data['usage'][0]);
                setgeneralData(res.data)
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);



    return (
        <div className="row">
            {msg}
            <h2>{t('systemusage')}</h2>
            <form onSubmit={systemusage}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">{t('title')}</label>
                        <textarea class="form-control" id="title" defaultValue={usage.title} />
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-3">
                        <label for="videoLink" class="form-label"> <b>{t('videolink')}  : </b>  {usage.videoLink}</label>
                    </div>
                </div>
                {usage.paragraphs && usage.paragraphs.map((dt) => {
                    return (
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for={dt.id} class="form-label">{t('paragraphs')} {dt.id}</label>
                                <textarea rows={5} class="form-control" id={dt.id} defaultValue={dt.text} />
                            </div>
                        </div>
                    )
                })
                }
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
            </form>
            <form onSubmit={handleVideo}>
                <div class="mb-3">
                    <label for="videocontent" class="form-label">{t("addVideo")}</label>
                    <input class="form-control" type="file" accept='video' onChange={handleChange} id="videocontent" name="videocontent" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>

        </div>
    )
}
export default SystemUsage;