import React, { useState, useEffect } from "react";
import './assets/css/style.css';
import './assets/css/icomoon.css'
import './assets/css/vendor.css'
import './assets/css/fonts.css'
import axios from "axios";
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Reviews() {
    const [data, setData] = useState([]);
    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);
    const [testimonials, setTestimonials] = useState([]);

    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getreviews",
                headers: headerss
            }).then(res => {
                setData(res.data)
            }
            );
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {

                setTestimonials(res.data['testimonials'][0])
            }
            );
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }


    useEffect(() => {
        getData();
        fetchData();
    }, [])
    return (
        <section id="testimonials" class="ud-testimonials" style={{ marginTop: "-5%" }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ud-section-title mx-auto text-center">
                            <span>{testimonials['title']}</span>
                            <h2>{testimonials['subTitle']}</h2>
                            <p>
                                {testimonials['text']}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">

                    {data && data.map((dt) => {
                        if (dt.isactive) {
                            return (
                                <div class="col-lg-4 col-md-6">
                                    <div
                                        class="ud-single-testimonial wow fadeInUp"
                                        data-wow-delay=".1s"
                                    >
                                        <div class="ud-testimonial-ratings">
                                            <FontAwesomeIcon icon="fas fa-star" />
                                            <FontAwesomeIcon icon="fas fa-star" />
                                            <FontAwesomeIcon icon="fas fa-star" />
                                            <FontAwesomeIcon icon="fas fa-star" />
                                            <FontAwesomeIcon icon="fas fa-star" />

                                        </div>
                                        <div class="ud-testimonial-content">
                                            <p>
                                                {dt.message}
                                            </p>
                                        </div>
                                        <div class="ud-testimonial-info">

                                            <div class="ud-testimonial-meta">
                                                <h4>{dt.namesurname}</h4>
                                                <p>{dt.info}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            )
                        }

                    })}





                </div>
            </div>
        </section>
    );
}

export default Reviews;