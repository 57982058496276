import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

function ReviewList() {
    const [data, setData] = useState([]);
    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);





    const handleEdit = (event) => {
        window.location.href = "/dashboard/admin/editreview/" + event.currentTarget.id;
    }
    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getreviews",
                headers: headerss
            }).then(res => {
                setData(res.data)
            }
            );
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }



    useEffect(() => {
        getData();
    }, [])


    return (
        <div style={{ backgroundColor: "white" }}>
            {msg}
            <div style={{ textAlign: "center" }}>
                <h1>{t("reviewlist")}</h1>
                <br />
            </div>
            <div style={{ overflowX: "auto", border: "1px solid black", padding: "5px" }}>
                <table class="table table-bordered" >
                    <thead>

                        <tr>
                            <th scope="col">{t("namesurname")}</th>
                            <th scope="col">{t("reviewUserDescription")}</th>

                            <th scope="col">{t("message")}</th>
                            <th scope="col">{t("reviewRatePoint")}</th>
                            <th scope="col">{t("isActive")}</th>
                            <th scope="col">{t("edit")}</th>


                        </tr>
                    </thead>
                    <tbody>

                        {data && data.map((dt) => {
                            return (
                                <tr key={dt.id}>
                                    <td>{dt.namesurname}</td>
                                    <td>{dt.info}</td>
                                    <td>{dt.message}</td>
                                    <td>{dt.star}</td>
                                    <td>{dt.isactive ? "Aktif" : "Pasif"}</td>

                                    <td>
                                        <button className="btn btn-info" id={dt.id} onClick={handleEdit}>
                                            {t("edit")}
                                        </button>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <br />
            </div> <br />
        </div>
    );
};
export default ReviewList;
