import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';

export const GeneralSettings = () => {
    const [image, setImage] = useState('');
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    const [registerData, setregisterData] = useState('');
    const [logo, setLogo] = useState('');

    const [generalData, setgeneralData] = useState([]);



    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImage(res.data['data']['path'])
                alert("yüklendi")
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    const handleFile = async (e) => {
        e.preventDefault();
        if (image != null) {
            logo['logoUrl'] = "https://api.heryerdeingilizce.com/" + image;
            generalData.genel[0] = logo
            try {
                const headerss = {
                    "accept": "*/*",
                    'Access-Control-Allow-Credentials': 'false',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
                await axios({
                    method: 'post',
                    url: "https://express.heryerdeingilizce.com/main",
                    data: JSON.stringify(generalData),
                    headers: headerss
                }).then(response => {
                    alert("güncellendi")
                    window.location.reload();
                });
            }
            catch (err) {
                setMsg(err);
            }
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        registerData['isActive'] = document.getElementById("activite").value;
        generalData.notRegister[0] = registerData

        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            }).then(response => {
                alert("güncellendi")
                window.location.reload();
            });
        }
        catch (err) {
            setMsg(err);
        }
    }


    const handleregister = async (e) => {
        e.preventDefault();
        registerData['title'] = document.getElementById("title").value;
        registerData['subtitle'] = document.getElementById("subtitle").value;
        generalData.notRegister[0] = registerData
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            }).then(response => {
                alert("güncellendi")
                window.location.reload();
            });

        }
        catch (err) {
            setMsg(err);
        }
    }

    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {

                setregisterData(res.data['notRegister'][0]);
                setLogo(res.data['genel'][0])
                setgeneralData(res.data)
            }
            );
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <div className="row">
            {msg}
            <h2>{t('generalsettingsforhomepage')}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <img src={logo['logoUrl']} alt="logo" style={{ padding: "10%" }} class="img-fluid" />

                </div>
                <div className='col-md-6'>
                    <br />
                    <span>Logo'da görünecek görsel. -***x*** boyutlarında olmalıdır.</span>
                    <form onSubmit={handleFile}>
                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                        <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                    </form>


                </div>

            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <h2>{t('NotRegisterSettings')}</h2>
                    <h6>{t('currentStatus')} : {registerData['isActive']}</h6>
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value="pasif">{t('passive')}</option>
                                <option key="2" value="aktif">{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                    </form>

                </div>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <form onSubmit={handleregister} >
                        <div class="mb-3">
                            <label for="title" class="form-label"> {t('title')}</label>
                            <textarea class="form-control" id="title" defaultValue={registerData.title} />
                        </div>
                        <div class="mb-3">
                            <label for="subtitle" class="form-label">{t('subTitle')}</label>
                            <textarea class="form-control" id="subtitle" defaultValue={registerData.subtitle} />
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default GeneralSettings;