import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

export const Contactus = () => {
    const [contact, setContact] = useState([]);
    const { t } = useTranslation();
    const [generalData, setgeneralData] = useState([]);
    const [msg, setMsg] = useState([]);


    const handleSubmit = async (e) => {
        const data = {
            "id": contact.id,
            "isActive": document.getElementById("activite").value,
        }
        generalData.contactUs[0] = data
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            }).then(response => {
                getData();
                window.location.reload();

            });

        }
        catch (err) {
            setMsg(err);
        }
    }

    const getData = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setContact(res.data['contactUs'][0])
                setgeneralData(res.data)
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <div className="row">
            {msg}
            <br />
            <h2>{t('Contactussection')}</h2>
            <div className='row'>
                <h6>{t('currentStatus')} : {contact.isActive}</h6>
                <form onSubmit={handleSubmit} >
                    <div class="mb-3">
                        <label for="activite" class="form-label">{t("sectionActivite")}</label>
                        <select id="activite" class="form-select" aria-label="Default select example">
                            <option key="0" value="0">{t("sectionActivite")}</option>
                            <option key="1" value="pasif">{t('passive')}</option>
                            <option key="2" value="aktif">{t('active')}</option>

                        </select>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                    </div>
                </form>

            </div>
            <hr />

        </div>
    )
}
export default Contactus;