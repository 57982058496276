import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import axios from "axios";


export const AdminCreateSubject = () => {
    const [courses, setCourses] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();
    const [imagePath, setImagePath] = useState(null);
    const [sections, setSections] = useState([]);
    const [SortIdValue, setSortIdValue] = useState([]);


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("Yüklendi!")
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path'];
                setImagePath(url)
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (SortIdValue === 0) {
            window.location.reload();
        }

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/Create";

            await axios({
                method: 'Post',
                url: url,
                data: {
                    subjectName: document.getElementById("subjectName").value,
                    subjectDesc: document.getElementById("subjectDesc").value,
                    courseId: document.getElementById("courseId").value,
                    sortId: SortIdValue,
                    featuredImage: imagePath,
                    isActive: true,
                    isDeleted: false
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });

        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
        window.location.reload();
    }

    const getCourses = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCoursesBySection";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    id: document.getElementById("sectionId").value
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setCourses(res.data['data'])
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }
    const handleSelectChange = async (event) => {
        getCourses()
    }
    const handleCourseChange = async (event) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetListByCourse";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    id: document.getElementById("courseId").value
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        if (res.data['data'].length !== 0) {
                            setSortIdValue(Math.max.apply(Math, res.data['data'].map(function (o) { return o.sortId; })) + 1)
                        }
                        else {
                            setSortIdValue(1)
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }
    const getSections = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/GetList";

            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {

                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let activity = "";
                            if (res.data['data'][i]['isActive']) {
                                activity = t('active');
                            }
                            else {
                                activity = t('passive');
                            }
                            sections.push({
                                "id": res.data['data'][i]['id'],
                                "sectionName": res.data['data'][i]['sectionName'],
                                "sectionDescription": res.data['data'][i]['sectionDescription'],
                                "sortId": res.data['data'][i]['sortId'],
                                "isActive": activity,
                            })
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    useEffect(() => {
        setImagePath("https://api.heryerdeingilizce.com/photo/91ebd6aa-6a51-4dcc-ad27-5633dbddd6e6.png")
        setSortIdValue(0)
        getSections();

    }, [])
    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createSubject")}</h1>
            </div>
            <h3>{msg}</h3>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <form onSubmit={onSubmit} >

                    <div class="mb-3">
                        <label for="subjectName" class="form-label">{t("subjectName")}</label>
                        <textarea class="form-control" id="subjectName" required />
                    </div>
                    <div class="mb-3">
                        <label for="subjectDesc" class="form-label">{t("subjectDescription")}</label>
                        <textarea class="form-control" id="subjectDesc" required />
                    </div>


                    <div class="mb-3">
                        <label for="sectionId" class="form-label">{t("selectSection")}</label>
                        <select id="sectionId" class="form-select" onChange={handleSelectChange}>
                            <option key="0" value="0">bölüm seçiniz</option>

                            {sections ? (
                                sections && sections.map((dt) => {

                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.sectionName} -- {dt.sectionDescription}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="courseId" class="form-label">{t("selectCourse")}</label>
                        <select id="courseId" class="form-select" onChange={handleCourseChange}>
                            <option key="0" value="0">kurs seçiniz</option>

                            {courses ? (
                                courses && courses.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.courseName} -- {dt.courseDesc}</option>
                                    );

                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="schoollogo" class="form-label">{t("image")}</label>
                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                    </div>

                </form>
            )}
        </div>
    )
}
export default AdminCreateSubject;