import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

function TeacherClassSettings() {
    const { t } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams();
    const [data, setData] = useState([]);
    const [sections, setSections] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/UpdateClass";
            await axios({
                method: 'Put',
                url: url,
                data: {
                    className: document.getElementById("className").value,
                    description: document.getElementById("classdescription").value,
                    id: data.id,
                    isDeleted: data.isDeleted,
                    schoolId: data.schoolId,
                    lecturerId: document.getElementById("lecturerId").value,
                    sectionId: document.getElementById("sectionId").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetLecturers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    teachers.push({
                                        "id": res.data['data'][i]['id'],
                                        "fullName": res.data['data'][i]['fullName'],
                                    })
                                }

                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            //-----------------------------
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetSections";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data']['sections'].length; i++) {
                                if (!res.data['data']['sections'][i]['isDeleted']) {
                                    if (res.data['data']['sections'][i]['isActive']) {
                                        sections.push({
                                            id: res.data['data']['sections'][i]['id'],
                                            sectionName: res.data['data']['sections'][i]['sectionName'],
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            //-----------------------------


            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetClassById";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        classId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        console.log(res.data['data']);
                        setData(res.data['data'])
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }




        })()
    }, [])


    return (
        <div>
            <h5>{msg}</h5>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t('editclass')}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div class="mb-3">
                            <label for="className" class="form-label">{t('className')}</label>
                            <textarea type="text" class="form-control" name="className" id="className" defaultValue={data.className} />
                        </div>
                        <div class="mb-3">
                            <label for="classdescription" class="form-label">{t('classdescription')}</label>
                            <textarea type="text" class="form-control" name="classdescription" id="classdescription" defaultValue={data.description} />
                        </div>


                        <div class="mb-3">
                            <label for="lecturerId" class="form-label">{t('teacherName')}</label>
                            <select id="lecturerId" class="form-select" required >
                                {teachers ? (
                                    teachers && teachers.map((dt) => {
                                        if (dt.id === data.lecturerId) {
                                            return (
                                                <option key={dt.id} value={dt.id} selected>{dt.fullName}</option>
                                            )
                                        }
                                        else {
                                            return (
                                                <option key={dt.id} value={dt.id}>{dt.fullName}</option>
                                            )
                                        }
                                    })
                                ) : (
                                    <p>{t('refleshPage')}</p>
                                )
                                }
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="sectionId" class="form-label">{t('sectionName')}</label>
                            <select id="sectionId" name="sectionId" class="form-select" required>
                                <option key="" value="">{t('pleaseselectsection')}</option>
                                {sections ? (
                                    sections && sections.map((dt) => {

                                        if (dt.id === data.sectionId) {
                                            return (
                                                <option key={dt.id} value={dt.id} selected>{dt.sectionName}</option>
                                            )
                                        }
                                        else {
                                            return (
                                                <option key={dt.id} value={dt.id}>{dt.sectionName}</option>
                                            )
                                        }



                                    })
                                ) : (
                                    <p>{t('refleshPage')}</p>
                                )
                                }
                            </select>
                        </div>

                        {/* 
                        <div class="mb-3">
                            <label for="isDeleted" class="form-label">{t('isDeleted')}</label>

                            {data.isDeleted ? (
                                <select id="isDeleted" class="form-select" required >
                                    <option key="1" value="true" selected>{t('deleted')}</option>
                                    <option key="0" value="false" >{t('active')}</option>
                                </select>

                            ) : (
                                <select id="isDeleted" class="form-select" required >
                                    <option key="0" value="false" selected>{t('active')}</option>
                                    <option key="1" value="true" >{t('deleted')}</option>
                                </select>
                            )}
                        </div> */}





                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">{t('update')}</button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default TeacherClassSettings;
