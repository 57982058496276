import axios from "axios";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "bootstrap";
import RealMatch from "./QuestionTypes/RealMatch"
import VideoContent from "./QuestionTypes/VideoContent"
import Choice from "./QuestionTypes/Choice"
import Write from "./QuestionTypes/Write";
import Match from "./QuestionTypes/Match";
import TrueFalse from "./QuestionTypes/TrueFalse";
import Sentence from "./QuestionTypes/Sentence";
import Pagination from "./QuestionTypes/Pagination";
export const UserQuestion = () => {
    const params = useParams();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataa, setDataa] = useState([]);
    const [questionType, setquestionType] = useState([]);
    const [questionCount, setquestionCount] = useState([]);
    const [questionNumber, setquestionNumber] = useState([]);
    const [truefalseanswercount, settruefalseanswercount] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [answered, setAnswered] = useState([]);
    const [realAnswer, setrealAnswer] = useState([]);
    const [paginationAnswers, setpaginationAnswers] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        setAnswered(false)
        let realAnswers = [];
        const report = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Opt/MyReport";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            realAnswers = res.data['data']
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        report();



        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetContents";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        sortId: params["subjectid"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setquestionCount(res.data['data']['contents'].length) // toplam soru sayısı
                            if (Number(params["id"]) > res.data['data']['contents'].length) {
                                navigate("/dashboard/user/course/subject");
                            }
                            else {
                                setquestionNumber(res.data['data']['contents'][Number(params['id']) - 1]['id']) // hangi sorudaysak onun idsi
                                let realData = res.data['data']['contents'][Number(params['id']) - 1]  // hangi sorudaysak onun içeriği
                                setDataa(realData);
                                let questionId = res.data['data']['contents'][Number(params['id']) - 1]['id'];
                                for (let a = 0; a < realAnswers.length; a++) {
                                    if (realAnswers[a].questionId === questionId) {
                                        setAnswered(true)
                                        if (realAnswers[a].isTrue) {

                                            setrealAnswer("Doğru")
                                        }
                                        else {

                                            setrealAnswer("Yanlış")
                                        }
                                    }
                                }

                                if (res.data['data']['contents'].length !== 0) {
                                    for (let i = 0; i < res.data['data']['contents'].length; i++) {
                                        // console.log(res.data['data']['contents'][i]['id'])
                                        let num = Number(i) + 1
                                        let activity = false;
                                        if (Number(params['id'] - 1) === Number(i)) {
                                            activity = true;
                                        }
                                        let questionisanswered = false
                                        let questionanswercorrect = false
                                        //tüm kontent içinde cevaplanan paginationları bulmak için
                                        for (let a = 0; a < realAnswers.length; a++) {

                                            if (realAnswers[a].questionId === res.data['data']['contents'][i]['id']) {
                                                questionisanswered = true
                                                if (realAnswers[a].isTrue) {
                                                    questionanswercorrect = true
                                                }
                                            }

                                            // else {
                                            //     console.log("no cevap soru id: " + res.data['data']['contents'][i]['id'])
                                            // }
                                        }
                                        pagination.push(
                                            {
                                                "id": i + 1,
                                                "href": "/dashboard/user/course/question/" + params['subjectid'] + "/" + num.toString(),
                                                "isActive": activity,
                                                "answered": questionisanswered,
                                                "answerCorrect": questionanswercorrect
                                            }
                                        )
                                    }
                                }

                                setquestionType(res.data['data']['contents'][Number(params['id']) - 1]['contentType'])
                                setAnswer(res.data['data']['contents'][Number(params['id']) - 1]['answer'])
                                let x = JSON.parse(realData['detail'])
                                settruefalseanswercount(x.length)
                            }

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    if (!loading) {
        if (questionType === 'video') {
            return (
                <div style={{ position: "relative" }}>
                    <VideoContent data={dataa} questionCount={questionCount} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div>
            )
        }
        else if (questionType === 'write') {
            return (
                <div>
                    <Write data={dataa} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'choice') {
            return (
                <div  >
                    <Choice data={dataa} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />

                </div >
            )
        }
        else if (questionType === 'match') {
            return (
                <div>
                    <Match data={dataa} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'truefalse') {
            return (
                <div >
                    <TrueFalse data={dataa} truefalseanswercount={truefalseanswercount} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'realmatch') {
            return (
                <div>
                    <br />
                    <RealMatch data={dataa} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    {!loading && (
                        <Pagination pagination={pagination} />

                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'sentence') {
            return (
                <div>
                    <Sentence data={dataa} questionNumber={questionNumber} answer={answer} answered={answered} realanswer={realAnswer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div >
            )
        }


    }
}
export default UserQuestion;