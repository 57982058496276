import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import Swal from 'sweetalert2'

function AdminSchoolList() {
    const [data, setData] = useState([]);
    const [passivedata, setpassivedata] = useState([]);

    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()

    const columns = React.useMemo(
        () => [
            {
                Header: t("schoolId"),
                Footer: t("schoolId"),
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: t("schoolName"),
                Footer: t("schoolName"),
                accessor: 'schoolName', // accessor is the "key" in the data
            },

            {
                Header: t("studentLimit"),
                Footer: t("studentLimit"),
                accessor: 'studentLimit',
            },
            {
                Header: t("createdAt"),
                Footer: t("createdAt"),
                accessor: 'createdAt',
            },
            {
                Header: t("finishDate"),
                Footer: t("finishDate"),
                accessor: 'expiryDate',
            },

            {
                Header: t("idDeleted"),
                Footer: t("idDeleted"),
                accessor: 'idDeleted',
            },
            {
                Header: t("isActive"),
                Footer: t("isActive"),
                accessor: 'isActive',
            },
            {
                Header: t("edit"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t("delete")}
                        </button> */}
                    </div>
                ),
                id: 'action',
                Footer: 'Düzenle',
            },
        ],
        [],
    )
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/School/AssignManager";
            await axios({
                method: 'Get',
                url: url,
                params: {
                    email: document.getElementById("email").value,
                    schoolId: document.getElementById("schoolId").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        document.getElementById("email").value = "";
                        document.getElementById("schoolId").value = "";

                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    Swal.fire({
                        title: "hata!",
                        icon: "error",
                        confirmButtonText: `Tamam`
                    });
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            Swal.fire({
                title: err.message,
                icon: "error",
                confirmButtonText: `Tamam`
            });
            setMsg(err);
        }
    }

    const handleEdit = (row) => {
        window.location.href = "editschool/" + row['id'];
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/School/SchoolList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {
                            console.log(res.data['data'][0]['isActive']);
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let b = "";
                                if (res.data['data'][i]['isDeleted']) {
                                    b = t('deleted')
                                }
                                else {
                                    b = t('active')
                                }
                                let a = "";
                                if (res.data['data'][i]['isActive']) {
                                    a = t('active')
                                    data.push({
                                        "id": res.data['data'][i]['id'],
                                        "schoolName": res.data['data'][i]['schoolName'],
                                        "studentLimit": res.data['data'][i]['studentLimit'],
                                        "createdAt": res.data['data'][i]['createdAt'],
                                        "expiryDate": res.data['data'][i]['expiryDate'],
                                        "isActive": a,
                                        "idDeleted": b
                                    })
                                }
                                else if (!res.data['data'][i]['isActive']) {
                                    a = t('passive')
                                    passivedata.push({
                                        "id": res.data['data'][i]['id'],
                                        "schoolName": res.data['data'][i]['schoolName'],
                                        "studentLimit": res.data['data'][i]['studentLimit'],
                                        "createdAt": res.data['data'][i]['createdAt'],
                                        "expiryDate": res.data['data'][i]['expiryDate'],
                                        "isActive": a,
                                        "idDeleted": b
                                    })
                                }
                            }
                        }
                        else {

                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);

    return (

        <div>
            <h4>{msg}</h4>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}

            {!loading && (


                <div class="accordion accordion-flush" id="accordionExample" style={{ backgroundColor: "white" }}>
                    <div class="accordion-item" >
                        <h2 class="accordion-header" style={{ backgroundColor: "gray" }}>
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <b>{t("schoolList")}</b>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <Datatable data={data} columns={columns} tableBaslik={t("schoolList")} />
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" style={{ backgroundColor: "gray" }}>
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <b>{t("passiveschoolList")} </b>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <Datatable data={passivedata} columns={columns} tableBaslik={t("passiveschoolList")} />
                            </div>
                        </div>
                    </div>

                </div>










            )}


            <div style={{ textAlign: "center" }}>

                <form onSubmit={handleSubmit} style={{ border: "1px solid gray", margin: "3%" }}>

                    <div class="mb-3">
                        <label for="email" class="form-label">{t("userEmail")}</label>
                        <textarea class="form-control" id="email" required />
                    </div>
                    <div class="mb-3">
                        <label for="schoolId" class="form-label">{t("schoolId")}</label>
                        <textarea class="form-control" id="schoolId" required />
                    </div>


                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                    </div>
                    <br />
                </form>
            </div>
        </div>

    );
}


export default AdminSchoolList;