import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';

export const CreateModerator = () => {

    const { t } = useTranslation()
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/User/CreateUser";
            let isTrial = false;
            let isVerified = true;
            let isManager = false;
            let isStudent = false;
            let isInvidual = false;
            let roleId = 4;
            let sectionId = null;
            let schoolId = null;
            let classId = null;
            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: document.getElementById("email").value,
                    password: document.getElementById("password").value,
                    fullName: document.getElementById("nameandsurname").value,
                    sectionId: sectionId,
                    isTrial: isTrial,
                    isVerified: isVerified,
                    isManager: isManager,
                    isStudent: isStudent,
                    isInvidual: isInvidual,
                    roleId: roleId,
                    schoolId: schoolId,
                    classId: classId
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                alert(res.status)
                if (res.status === 200) {
                    if (res.data['success']) {
                        console.log(res.data);
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <h5>{msg}</h5>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createmoderator")}</h1>
            </div>
            <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="email" class="form-label">{t("email")}</label>
                    <input type="email" class="form-control" id="email" placeholder={t('validemail')} required />
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">{t("namesurname")}</label>
                    <textarea class="form-control" id="name" placeholder={t('enternamesurname')} required />
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">{t("userPassword")}</label>
                    <input type="password" class="form-control" id="password" placeholder={t('enterpassword')} />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>
        </div>
    )
}
export default CreateModerator;