import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';

const AnswerForm = (props) => {
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();

    const handleServiceRemove = (index) => {
        const list = [...props.serviceList];
        list.splice(index, 1);
        props.setServiceList(list);
    };
    const handleServiceAdd = () => {
        props.setServiceList([...props.serviceList, { service: "" }]);
    };
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...props.serviceList];
        list[index][name] = value;
        props.setServiceList(list);
    };


    const handleAnswerPhoto = async (e, index) => {
        if (e.target.type === "textarea") {
            let url = e.target.value;
            const { name, value } = e.target;
            const list = [...props.serviceList];
            list[index]["photo"] = url;
            props.setServiceList(list);
        }
        else if (e.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', e.target.files[0]);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
                await axios({
                    method: 'Post',
                    url: url,
                    params: {
                        mediaType: "photo"
                    },
                    data: formData,
                    headers: headerss
                }).then(res => {
                    let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                    alert("Yüklendi")
                    const { name, value } = e.target;
                    const list = [...props.serviceList];
                    list[index][name] = url;;
                    props.setServiceList(list);
                });
            } catch (err) {
                setMsg(err.message);
            }
        }

    };

    const handleAnswerVideo = async (e, index) => {
        if (e.target.type === "textarea") {
            let url = e.target.value;
            const list = [...props.serviceList];
            list[index]["video"] = url;
            props.setServiceList(list);
        }
        else if (e.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', e.target.files[0]);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
                await axios({
                    method: 'Post',
                    url: url,
                    params: {
                        mediaType: "video"
                    },
                    data: formData,
                    headers: headerss
                }).then(res => {
                    let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                    alert("Yüklendi")
                    const { name, value } = e.target;
                    const list = [...props.serviceList];
                    list[index][name] = url;;
                    props.setServiceList(list);
                });
            } catch (err) {
                setMsg(err.message);
            }
        }

    };
    const handleAnswerSound = async (e, index) => {
        if (e.target.type === "textarea") {
            let url = e.target.value;
            const { name, value } = e.target;

            const list = [...props.serviceList];
            list[index]["sound"] = url;
            props.setServiceList(list);
        }
        else if (e.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', e.target.files[0]);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
                await axios({
                    method: 'Post',
                    url: url,
                    params: {
                        mediaType: "sound"
                    },
                    data: formData,
                    headers: headerss
                }).then(res => {
                    let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                    alert("Yüklendi")
                    const { name, value } = e.target;
                    const list = [...props.serviceList];
                    list[index][name] = url;;
                    props.setServiceList(list);
                });
            } catch (err) {
                setMsg(err.message);
            }
        }

    };


    return (
        <form className="App" autoComplete="off">
            <div className="form-field">
                <h5>{t('answers')}</h5>
                {props.serviceList.map((singleService, index) => (
                    <div key={index} className="services">
                        <div className="first-division">
                            {props.file === true &&
                                <div className="row" style={{ padding: "1%" }}>
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="photo" class="form-label">{t("addPhoto")}</label>
                                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto(e, index)} id="photo" name="photo" required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="photoUrl" class="form-label">{t("addPhotobyUrl")}</label>
                                            <textarea class="form-control" rows={1} type="text" onChange={(e) => handleAnswerPhoto(e, index)} id="photoUrl" name="photoUrl" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="video" class="form-label">{t("addVideo")}</label>
                                            <input class="form-control" type="file" accept='video' onChange={(e) => handleAnswerVideo(e, index)} id="video" name="video" required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="videoUrl" class="form-label">{t("addVideobyUrl")}</label>
                                            <textarea class="form-control" rows={1} type="text" onChange={(e) => handleAnswerVideo(e, index)} id="videoUrl" name="videoUrl" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div class="mb-1">
                                            <label for="sound" class="form-label">{t("addSound")}</label>
                                            <input class="form-control" type="file" accept=".mp4,.mp3,audio/*" onChange={(e) => handleAnswerSound(e, index)} id="sound" name="sound" required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="soundUrl" class="form-label">{t("addSoundbyUrl")}</label>
                                            <textarea class="form-control" rows={1} type="text" onChange={(e) => handleAnswerSound(e, index)} id="soundUrl" name="soundUrl" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <h6 for="service" class="form-label">{t("answerText")}</h6><i>boş ise 1 ader boşluk giriniz. (sadece video/resim/ses var ise)</i> <i>{props.infoText}</i>
                            <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                            <hr />
                            {props.serviceList.length - 1 === index && props.serviceList.length < 6 && (
                                <button
                                    type="button"
                                    onClick={handleServiceAdd}
                                    className="add-btn"
                                >
                                    <span>{t('addAnswerOption')}</span>
                                </button>
                            )}
                        </div>
                        <div className="second-division">
                            {props.serviceList.length !== 1 && (
                                <button
                                    type="button"
                                    onClick={() => handleServiceRemove(index)}
                                    className="remove-btn"
                                >
                                    <span>{t('remove')}</span>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div className="output">
                <h2>{t('answers')}</h2>
                {props.serviceList &&
                    props.serviceList.map((singleService, index) => (
                        <ul key={index}>
                            {singleService.service &&
                                <li>
                                    {index + 1} = Text:{singleService.service} <br /> Image Url: {singleService.photo} <br /> Video Url: {singleService.video} <br /> Sound Url: {singleService.sound}
                                </li>}
                        </ul>
                    ))}
            </div>
        </form>
    );
};

export default AnswerForm;