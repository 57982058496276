import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from "react-router-dom";


export const UserSubjectLayout = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [subjects, setSubjects] = useState([]);
    const [activeCourse, setactiveCourse] = useState([]);
    const navigate = useNavigate();



    const handleClick = event => {
        navigate("/dashboard/user/course/question/" + event.target.id + "/1");
    };
    const handleReport = event => {
        alert(event.target.id)
    };


    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetSubject";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setSubjects(res.data['data']['subjects'])
                            setactiveCourse(res.data['data']['subject'])
                            console.log(res.data['data'])
                            console.log(res.data['data']['subject'])
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    return (
        <div >
            <div className="row">
                <h4>{msg}</h4>
                {!loading && (
                    <div>
                        {subjects && subjects.map((dt) => {
                            if (dt.id === activeCourse.id) {

                                return (
                                    <div style={{ marginTop: "1%", backgroundColor: "Cornsilk" }} class="card card-primary card-outline" key={dt.id}>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="card-body" >
                                                    <h5 class="card-title">#Aktif Konu == {dt.subjectName}</h5>
                                                    <hr />
                                                    <p class="card-text">
                                                        {dt.subjectDesc}
                                                    </p>
                                                    <a onClick={handleClick} id={dt.sortId} class="btn btn-primary">Soruları Gör</a>
                                                    <a onClick={handleReport} id={dt.id} class="btn btn-info">Raporu Gör</a>
                                                </div>
                                            </div>
                                            <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                                <img style={{ maxHeight: "100px" }} src={dt.featuredImage} class="img-fluid rounded-start" alt="..." />

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div style={{ marginTop: "1%", backgroundColor: "Gainsboro" }} class="card card-primary card-outline" key={dt.id}>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="card-body">
                                                    <h5 class="card-title">{dt.subjectName}</h5>
                                                    <hr />
                                                    <p class="card-text">
                                                        {dt.subjectDesc}

                                                    </p>

                                                    <a onClick={handleClick} id={dt.sortId} class="btn btn-primary">Soruları Gör</a>
                                                    <a onClick={handleReport} id={dt.id} class="btn btn-info">Raporu Gör</a>



                                                </div>
                                            </div>
                                            <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                                <img style={{ maxHeight: "100px" }} src={dt.featuredImage} class="img-fluid rounded-start" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                )}


            </div>
        </div>

    );
}
export default UserSubjectLayout;



