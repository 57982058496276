import { useTranslation } from "react-i18next";
import Page404 from "./pages/Page404";
import 'bootstrap/dist/css/bootstrap.min.css';
import './general.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import HomeLayout from "./pages/Home/HomeLayout";
import HomePage from "./pages/Home/HomePage";
import Reviews from "./pages/Home/Reviews";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import VerificationEmail from "./pages/Auth/VerificationEmail";
import ProfileLayout from "./pages/Profile/ProfileLayout";
import TicketDetail from "./pages/Profile/TicketDetail";
import UserTicketDetail from "./pages/Profile/UserTicketDetail";
import MainProfile from "./pages/Profile/Profile";
import DashboardLayout from "./pages/Dashboard/DashboardLayout";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import UserSkill from "./pages/Dashboard/User/SubPages/UserSkill";
import UserGeneral from "./pages/Dashboard/User/SubPages/UserGeneral";
import UserList from "./pages/Dashboard/User/SubPages/UserList";
import AdminSchoolList from "./pages/Dashboard/Admin/SubPages/School/AdminSchoolList";
import AdminUserList from "./pages/Dashboard/Admin/SubPages/User/AdminUserList";
import AdminEditUser from "./pages/Dashboard/Admin/SubPages/User/AdminEditUser";
import AdminCreateSchool from "./pages/Dashboard/Admin/SubPages/School/AdminCreateSchool";
import AdminBank from "./pages/Dashboard/Admin/SubPages/Financial/AdminBank";
import AdminBankEdit from "./pages/Dashboard/Admin/SubPages/Financial/AdminBankEdit";
import AdminPackageEdit from "./pages/Dashboard/Admin/SubPages/Financial/AdminPackageEdit";
import AdminPackageList from "./pages/Dashboard/Admin/SubPages/Financial/AdminPackageList";
import AdminPackageandPrice from "./pages/Dashboard/Admin/SubPages/Financial/AdminPackageandPrice";
import AdminPayment from "./pages/Dashboard/Admin/SubPages/Financial/AdminPayment";
import AdminCourseList from "./pages/Dashboard/Admin/SubPages/Course/Course/AdminCourseList";
import AdminCreateCourse from "./pages/Dashboard/Admin/SubPages/Course/Course/AdminCreateCourse";
import AdminEditCourse from "./pages/Dashboard/Admin/SubPages/Course/Course/AdminEditCourse";
import AdminSectionList from "./pages/Dashboard/Admin/SubPages/Course/Section/AdminSectionList";
import AdminCreateSection from "./pages/Dashboard/Admin/SubPages/Course/Section/AdminCreateSection";
import AdminEditSection from "./pages/Dashboard/Admin/SubPages/Course/Section/AdminEditSection";
import AdminTagList from "./pages/Dashboard/Admin/SubPages/Course/Tag/AdminTagList";
import AdminCreateTag from "./pages/Dashboard/Admin/SubPages/Course/Tag/AdminCreateTag";
import AdminEditTag from "./pages/Dashboard/Admin/SubPages/Course/Tag/AdminEditTag";
import AdminCreateQuestion from "./pages/Dashboard/Admin/SubPages/Course/Question/Create/AdminCreateQuestion";
import AdminCreateQuestionDetail from "./pages/Dashboard/Admin/SubPages/Course/Question/Create/AdminCreateQuestionDetail";
import AdminUpdateQuestionDetail from "./pages/Dashboard/Admin/SubPages/Course/Question/Create/AdminUpdateQuestionDetail";
import AdminCreateQuestionBySubject from "./pages/Dashboard/Admin/SubPages/Course/Question/Create/AdminCreateQuestionBySubject";
import AdminQuestionList from "./pages/Dashboard/Admin/SubPages/Course/Question/QuestionDetails/AdminQuestionList";
import AdminQuestionListBySubject from "./pages/Dashboard/Admin/SubPages/Course/Question/QuestionDetails/QuestionListBySubject";
import AdminEditQuestion from "./pages/Dashboard/Admin/SubPages/Course/Question/QuestionDetails/AdminEditQuestion";
import AdminCreateBySortId from "./pages/Dashboard/Admin/SubPages/Course/Question/Create/AdminCreateBySortId";
import AdminCreateSubject from "./pages/Dashboard/Admin/SubPages/Course/Subject/AdminCreateSubject";
import AdminSubjectList from "./pages/Dashboard/Admin/SubPages/Course/Subject/AdminSubjectList";
import AdminEditSubject from "./pages/Dashboard/Admin/SubPages/Course/Subject/AdminEditSubject";
import ReviewList from "./pages/Dashboard/Admin/SubPages/Review/ReviewList";
import CreateReview from "./pages/Dashboard/Admin/SubPages/Review/CreateReview";
import EditReview from "./pages/Dashboard/Admin/SubPages/Review/EditReview";
import TeacherCreateClass from "./pages/Dashboard/School/Class/ManagerCreateClass";
import TeacherClassList from "./pages/Dashboard/Teacher/Class/TeacherClassList";
import TeacherClassSettings from "./pages/Dashboard/Teacher/Class/TeacherClassSettings";
import TeacherStudentCreate from "./pages/Dashboard/Teacher/Student/TeacherStudentCreate";
import TeacherEditStudent from "./pages/Dashboard/Teacher/Student/TeacherEditStudent";
import TeacherInfoSearch from "./pages/Dashboard/Teacher/Info/TeacherInfoSearch";
import SkillsInfo from "./pages/Dashboard/Teacher/Info/SkillsInfo";
import ProgressInfo from "./pages/Dashboard/Teacher/Info/ProgressInfo";
import TeacherSearchList from "./pages/Dashboard/Teacher/Student/TeacherSearchList";
import TeacherListDetails from "./pages/Dashboard/Teacher/Student/TeacherListDetails";
import SchoolCreateTeacher from "./pages/Dashboard/School/Teacher/SchoolCreateTeacher";
import SchoolEditTeacher from "./pages/Dashboard/School/Teacher/SchoolEditTeacher";
import SchoolTeacherList from "./pages/Dashboard/School/Teacher/SchoolTeacherList";
import EditSchool from "./pages/Dashboard/School/School/EditSchool";
import AdminPaymentEdit from "./pages/Dashboard/Admin/SubPages/Financial/AdminPaymentEdit";
import Slider from "./pages/Dashboard/Admin/SubPages/Homepage/Slider";


import FooterSettings from "./pages/Dashboard/Admin/SubPages/Homepage/FooterSettings";
import AboutUs from "./pages/Dashboard/Admin/SubPages/Homepage/AboutUs";
import CoreFeatures from "./pages/Dashboard/Admin/SubPages/Homepage/CoreFeatures";
import KvkkBilgi from "./pages/Home/KvkkBilgi";
import KullanimSozlesmesi from "./pages/Home/KullanimSozlesmesi";
import GeneralSettings from "./pages/Dashboard/Admin/SubPages/Homepage/GeneralSettings";
import SystemUsage from "./pages/Dashboard/Admin/SubPages/Homepage/SystemUsage";
import Testimonials from "./pages/Dashboard/Admin/SubPages/Homepage/Testimonials";
import GetContact from "./pages/Dashboard/Admin/SubPages/Review/GetContact";
import Packages from "./pages/Dashboard/Admin/SubPages/Homepage/Packages";
import Contactus from "./pages/Dashboard/Admin/SubPages/Homepage/Contactus";
import AdminCorporateUserList from "./pages/Dashboard/Admin/SubPages/School/AdminCorporateUserList";
import AdminEditCorporateUser from "./pages/Dashboard/Admin/SubPages/School/AdminEditCorporateUser";
import AdminEditSchool from "./pages/Dashboard/Admin/SubPages/School/AdminEditSchool";
import AuthLayout from "./pages/Auth/AuthLayout";
import ResetPasswordRequest from "./pages/Auth/ResetPasswordRequest";
import ResetPasswordVerify from "./pages/Auth/ResetPasswordVerify";
import CreateModerator from "./pages/Dashboard/Admin/SubPages/WebsiteUser/CreateModerator";
import ModeratorList from "./pages/Dashboard/Admin/SubPages/WebsiteUser/ModeratorList";
import SearchTicket from "./pages/Dashboard/Admin/SubPages/Ticket/SearchTicket";
import AdminTicketDetail from "./pages/Dashboard/Admin/SubPages/Ticket/TicketDetail";
import CoupenList from "./pages/Dashboard/Admin/SubPages/Coupen/CoupenList";
import EditCoupen from "./pages/Dashboard/Admin/SubPages/Coupen/EditCoupen";
import CreateCoupen from "./pages/Dashboard/Admin/SubPages/Coupen/CreateCoupen";
import ManagerCreateClass from "./pages/Dashboard/School/Class/ManagerCreateClass";
import ManagerClassList from "./pages/Dashboard/School/Class/ManagerClassList";
import ManagerEditClass from "./pages/Dashboard/School/Class/ManagerEditClass";
import UserCourseLayout from "./pages/Dashboard/User/Course/UserCourseLayout";
import UserSubjectLayout from "./pages/Dashboard/User/Course/UserSubjectLayout";
import UserQuestion from "./pages/Dashboard/User/Course/UserQuestion";


function App() {
  const { t, i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Routes >
        <Route path='*' element={<Page404 />} />
        <Route path="/" element={<HomeLayout />} >
          <Route index={true} element={<HomePage />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="kvkkbilgilendirme" element={<KvkkBilgi />} />
          <Route path="kullanicisozlesmesi" element={<KullanimSozlesmesi />} />
        </Route>
        <Route path="/profile" element={<ProfileLayout />}>
          <Route index={true} element={<MainProfile />} />
          <Route path="ticket/:id" element={<TicketDetail />} />
          <Route path="ticketdetail/:id" element={<UserTicketDetail />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="verifyemail/:email" element={<VerificationEmail />} />
          <Route path="resetpassword" element={<ResetPasswordRequest />} />
          <Route path="resetpasswordverify" element={<ResetPasswordVerify />} />
        </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index={true} element={<DashboardHome />} />
          <Route path="userskill" element={<UserSkill />} />
          <Route path="usergeneral" element={<UserGeneral />} />
          <Route path="userlist" element={<UserList />} />
          <Route path="admin/slider" element={<Slider />} />

          <Route path="admin/generalsettings" element={<GeneralSettings />} />
          <Route path="admin/aboutus" element={<AboutUs />} />
          <Route path="admin/corefeatures" element={<CoreFeatures />} />
          <Route path="admin/systemusage" element={<SystemUsage />} />
          <Route path="admin/testimonials" element={<Testimonials />} />
          <Route path="admin/getcontacts" element={<GetContact />} />
          <Route path="admin/mainpackages" element={<Packages />} />
          <Route path="admin/contactus" element={<Contactus />} />
          <Route path="admin/createmoderator" element={<CreateModerator />} />
          <Route path="admin/moderatorlist" element={<ModeratorList />} />
          <Route path="admin/searchticket" element={<SearchTicket />} />
          <Route path="admin/ticketdetail/:id" element={<AdminTicketDetail />} />
          <Route path="admin/footer" element={<FooterSettings />} />
          <Route path="admin/reviewlist" element={<ReviewList />} />
          <Route path="admin/createreview" element={<CreateReview />} />
          <Route path="admin/editreview/:id" element={<EditReview />} />
          <Route path="admin/createsection" element={<AdminCreateSection />} />
          <Route path="admin/sectionlist" element={<AdminSectionList />} />
          <Route path="admin/editsection/:id" element={<AdminEditSection />} />
          <Route path="admin/createtag" element={<AdminCreateTag />} />
          <Route path="admin/taglist" element={<AdminTagList />} />
          <Route path="admin/edittag/:id" element={<AdminEditTag />} />
          <Route path="admin/createcourse" element={<AdminCreateCourse />} />
          <Route path="admin/courselist" element={<AdminCourseList />} />
          <Route path="admin/editcourse/:id" element={<AdminEditCourse />} />
          <Route path="admin/createsubject" element={<AdminCreateSubject />} />
          <Route path="admin/subjectlist" element={<AdminSubjectList />} />
          <Route path="admin/createquestion" element={<AdminCreateQuestion />} />
          <Route path="admin/createquestionbysubject/:subject" element={<AdminCreateQuestionBySubject />} />
          <Route path="admin/createquestiondetail/:subject/:questiontype/:sort" element={<AdminCreateQuestionDetail />} />
          <Route path="admin/updatequestiondetail/:subject/:questiontype/:sort" element={<AdminUpdateQuestionDetail />} />
          <Route path="admin/questionlist" element={<AdminQuestionList />} />
          <Route path="admin/questionlistbysubject/:subject" element={<AdminQuestionListBySubject />} />
          <Route path="admin/editquestion/:id" element={<AdminEditQuestion />} />
          <Route path="admin/createquestionbysortid/:id" element={<AdminCreateBySortId />} />
          <Route path="admin/editsubject/:id" element={<AdminEditSubject />} />
          <Route path="admin/corporateuserlist" element={<AdminCorporateUserList />} />
          <Route path="admin/editcorporateuser/:id" element={<AdminEditCorporateUser />} />
          <Route path="admin/editschool/:id" element={<AdminEditSchool />} />
          <Route path="admin/userlist" element={<AdminUserList />} />
          <Route path="admin/edituser/:id" element={<AdminEditUser />} />
          <Route path="admin/schoollist" element={<AdminSchoolList />} />
          <Route path="admin/createschool" element={<AdminCreateSchool />} />
          <Route path="admin/bankaccount" element={<AdminBank />} />
          <Route path="admin/bankedit/:id" element={<AdminBankEdit />} />
          <Route path="admin/payment" element={<AdminPayment />} />
          <Route path="admin/coupenlist" element={<CoupenList />} />
          <Route path="admin/createcoupen" element={<CreateCoupen />} />
          <Route path="admin/editcoupen/:id" element={<EditCoupen />} />
          <Route path="admin/editpayment/:id/:type" element={<AdminPaymentEdit />} />
          <Route path="admin/packageprice" element={<AdminPackageandPrice />} />
          <Route path="admin/packageedit/:id" element={<AdminPackageEdit />} />
          <Route path="admin/packagelist" element={<AdminPackageList />} />
          <Route path="teacher/classlist" element={<TeacherClassList />} />
          <Route path="teacher/classcreate" element={<TeacherCreateClass />} />
          <Route path="teacher/classsettings/:id" element={<TeacherClassSettings />} />
          <Route path="teacher/studentlist/:id" element={<TeacherListDetails />} />
          <Route path="teacher/editstudent/:id" element={<TeacherEditStudent />} />
          <Route path="teacher/studentcreate" element={< TeacherStudentCreate />} />
          <Route path="teacher/studentinfo" element={< TeacherInfoSearch />} />
          <Route path="teacher/studentlist" element={< TeacherSearchList />} />
          <Route path="teacher/studentskills/class/:id?/student/:id?/level/:id?" element={< SkillsInfo />} />
          <Route path="teacher/studentprogress/class/:id?/student/:id?/level/:id?" element={< ProgressInfo />} />
          <Route path="school/teacherlist" element={<SchoolTeacherList />} />
          <Route path="school/editteacher/:id" element={<SchoolEditTeacher />} />
          <Route path="school/teachercreate" element={< SchoolCreateTeacher />} />
          <Route path="school/editschool/:id" element={< EditSchool />} />
          <Route path="school/createclass" element={<ManagerCreateClass />} />
          <Route path="school/classlist" element={<ManagerClassList />} />
          <Route path="school/editclass/:id" element={<ManagerEditClass />} />
          <Route path="user/course/" element={<UserCourseLayout />} />
          <Route path="user/course/subject" element={<UserSubjectLayout />} />
          <Route path="user/course/question/:subjectid/:id" element={<UserQuestion />} />
          <Route path='*' element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter >

  );
}

export default App;
