import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";


export const EditReview = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [msg, setMsg] = useState([]);
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState(true);






    function changeActivity(event) {
        event.preventDefault();
        if (document.getElementById('activite').value != 5) {
            const postData = {
                namesurname: data.namesurname,
                info: data.info,
                message: data.message,
                star: data.star,
                isactive: document.getElementById('activite').value
            }
            try {
                const headerss = {
                    "accept": "*/*",
                    'Access-Control-Allow-Credentials': 'false',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
                axios({
                    method: 'put',
                    url: "https://express.heryerdeingilizce.com/updatereview/" + params['id'],
                    data: postData,
                    headers: headerss
                }).then(response => {
                    if (response.status === 200) {
                        window.location.reload();
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
        }
        else {
            alert("Seçim Yapınız")
        }

    }


    function handleSubmit(event) {
        let form = document.getElementById("reviewform");
        const dataa = new FormData(form);
        let output = "";
        for (const entry of dataa) {
            output = `${output}${entry[1]}`;
        }
        const postData = {
            namesurname: document.getElementById("name").value,
            info: document.getElementById("description").value,
            message: document.getElementById("text").value,
            star: output,
            isactive: data.isactive
        }
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            axios({
                method: 'put',
                url: "https://express.heryerdeingilizce.com/updatereview/" + params['id'],
                data: postData,
                headers: headerss
            }).then(response => {
                if (response.status === 200) {
                    window.location.reload();
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }


    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getreviewsbyid/" + params["id"],
                headers: headerss
            }).then(res => {
                setData(res.data[0])
                if (res.data[0]['isactive']) {
                    setActivity("aktif")
                }
                else {
                    setActivity("pasif")
                }
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);

    }



    useEffect(() => {
        getData();
    }, [])

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            {msg}
            <div style={{ textAlign: "center" }}>
                <h1>{t("editreview")}</h1>
            </div>

            <form onSubmit={handleSubmit} id="reviewform">
                <div class="mb-3">
                    <span>{t("reviewRatePoint")} : {data.star}</span> <br /> Seçim yaparak güncelleme yapınız<br />
                    <fieldset class="rating" id="rating">
                        <input type="radio" id="star5" value="5" name="rating" />
                        <label for="star5">5 stars</label>
                        <input type="radio" id="star4" name="rating" value="4" />
                        <label for="star4">4 stars</label>
                        <input type="radio" id="star3" name="rating" value="3" />
                        <label for="star3">3 stars</label>
                        <input type="radio" id="star2" name="rating" value="2" />
                        <label for="star2">2 stars</label>
                        <input type="radio" id="star1" name="rating" value="1" />
                        <label for="star1">1 star</label>
                    </fieldset>
                </div>
                <br /><br />
                <div class="mb-3">
                    <label for="text" class="form-label">{t("message")}</label>
                    <textarea class="form-control" id="text" defaultValue={data.message} />
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">{t("namesurname")}</label>
                    <textarea class="form-control" id="name" defaultValue={data.namesurname} />
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label">{t("reviewdescription")}</label>
                    <textarea class="form-control" id="description" defaultValue={data.info} />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>

            <form onSubmit={changeActivity} id="reviewform">
                <div class="mb-3">
                    <label for="activite" class="form-label">Aktif mi?</label>
                    <h6>{t('currentStatus')} : {activity}</h6>

                    <select id="activite" class="form-select" aria-label="Default select example">
                        <option key="5" value="5">Aktif mi?</option>
                        <option key="0" value="0">{t('passive')}</option>
                        <option key="1" value="1">{t('active')}</option>

                    </select>
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>
        </div>
    );
};
export default EditReview;
