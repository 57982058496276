import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'

export const AdminCreateSchool = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()
    const [imagePath, setImagePath] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/School/Create";
            console.log(imagePath)
            await axios({
                method: 'Post',
                url: url,
                data: {
                    schoolName: document.getElementById("schoolName").value,
                    schoolLogo: imagePath,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        document.getElementById("schoolName").value = "";
                    }
                    else {
                        Swal.fire({
                            title: "error!",
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    Swal.fire({
                        title: "hata!",
                        icon: "error",
                        confirmButtonText: `Tamam`
                    });
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }



    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImagePath(res.data['data']['path'])
            });
        } catch (err) {
            setMsg(err.message);
        }
    }


    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <h4>{msg}</h4>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createSchool")}</h1>
            </div>

            <form onSubmit={handleSubmit} >

                <div class="mb-3">
                    <label for="schoolName" class="form-label">{t("schoolName")}</label>
                    <textarea class="form-control" id="schoolName" required />
                </div>

                <div class="mb-3">
                    <label for="schoollogo" class="form-label">{t("schoolImage")}</label>
                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>
        </div>
    )
}
export default AdminCreateSchool;