import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import axios from "axios";
import 'quill/dist/quill.snow.css'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';

export const AdminCreateQuestion = () => {
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [sortId, setSortId] = useState([]);
    const [courses, setCourses] = useState([]);

    const navigate = useNavigate();

    const questionTypes =
        [
            {
                "id": 1,
                "name": "video",
                "description": "Eğitim videosu ",
            },
            {
                "id": 2,
                "name": "choice",
                "description": "çoktan seçmeli /şıklı sorular için",
            },
            {
                "id": 3,
                "name": "UNSCREABLE",
                "description": "harflerden kelime/kelimelerden cümle",
            },
            {
                "id": 4,
                "name": "write",
                "description": "yazma",
            },
            {
                "id": 5,
                "name": "trueFalse",
                "description": "doğru yanlış",
            },
            {
                "id": 6,
                "name": "match",
                "description": "Toplu Eşleştirme / yazı yazı/resim resim drag-drop vb.",
            },
            {
                "id": 7,
                "name": "sentence",
                "description": "cümle içinde boş bırakılan yerdeki kelimeleri seçme",
            }
        ];

    const onSubmit = async (e) => {
        try {
            let questiontype = await document.getElementById("contentType").value;
            let subject = await document.getElementById("subjectId").value;
            navigate("/dashboard/admin/createquestiondetail/" + subject + "/" + questiontype + "/" + sortId);
        } catch (error) {
            alert(error.message);
        }

    }
    const onChange = async (e) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetListBySubject";

            await axios({
                method: 'GET',
                url: url,
                headers: headerss,
                params: {
                    subjectId: document.getElementById("subjectId").value
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        if (res.data['data'].length !== 0) {
                            setSortId(res.data['data'][res.data['data'].length - 1]['sortId'])
                        }
                        else {
                            setSortId(1)
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }

    }
    const realonChange = async (e) => {
        setSubjects(subjects => [])

        const fetchdataa = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetListByCourse";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss,
                    params: {
                        id: e.target.value
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isActive']) {
                                    setSubjects(subjects => [...subjects, res.data['data'][i]])
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }
    const GetCourses = async () => {
        try {
            await GeneralRequestHelper("/api/admin/CourseManager/GetCourses", "get", "", user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                if (res.data['data'][i]['isActive']) {
                                    courses.push({
                                        "id": res.data['data'][i]['id'],
                                        "courseName": res.data['data'][i]['courseName'],
                                        "courseDesc": res.data['data'][i]['courseDesc']
                                    })
                                }
                            }
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }


        setLoading(false);
    }
    useEffect(() => {
        GetCourses();
    }, [])



    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createQuestion")}</h1>
            </div>
            <h4>{msg}</h4>

            <form onSubmit={onSubmit} >
                <div class="mb-3">
                    <label for="courseId" class="form-label">{t("selectCourse")}</label>
                    <select id="courseId" class="form-select" onChange={realonChange}>
                        <option key="" >{t("selectCourse")}</option>

                        {courses ? (
                            courses && courses.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.courseName} -- {dt.courseDesc}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div class="mb-3">
                        <label for="subjectId" class="form-label">{t("selectSubject")}</label>
                        <select id="subjectId" class="form-select" onChange={onChange}>
                            <option key="" >{t("selectSubject")}</option>

                            {subjects ? (
                                subjects && subjects.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.subjectName} // {dt.subjectDesc}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>


                )}
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div class="mb-3">
                        <label for="contentType" class="form-label">{t("selectQuestionType")}</label>
                        <select id="contentType" class="form-select">
                            <option key="0" value={0}>{t('selectQuestionType')}</option>

                            {questionTypes ? (
                                questionTypes && questionTypes.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.name} - {dt.description}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                )}
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("startToCreate")}</button>
                </div>

            </form>


        </div>

    )
}
export default AdminCreateQuestion;



