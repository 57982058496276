import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";

export const Testimonials = () => {
    const { t } = useTranslation();
    const [testimonials, setTestimonials] = useState([]);
    const [testimonials1, setTestimonials1] = useState([]);
    const [testimonials2, setTestimonials2] = useState([]);
    const [testimonials3, setTestimonials3] = useState([]);
    const [generalData, setgeneralData] = useState([]);
    const [msg, setMsg] = useState([]);


    const testimonialsHandle = async (e) => {
        testimonials.title = document.getElementById("title").value;
        testimonials.subTitle = document.getElementById("subtitle").value;
        testimonials.text = document.getElementById("text").value;


        generalData.testimonials[0] = testimonials
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();


    }




    const test1 = async (e) => {
        testimonials1.star = document.getElementById("ratio1").value;
        testimonials1.message = document.getElementById("message1").value;
        testimonials1.nameSurname = document.getElementById("name1").value;
        testimonials1.info = document.getElementById("info1").value;
        generalData.mainpagetestimonials[0] = testimonials1
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();
    }
    const test2 = async (e) => {
        testimonials2.star = document.getElementById("ratio2").value;
        testimonials2.message = document.getElementById("message2").value;
        testimonials2.nameSurname = document.getElementById("name2").value;
        testimonials2.info = document.getElementById("info2").value;
        generalData.mainpagetestimonials[1] = testimonials2
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();
    }
    const test3 = async (e) => {
        testimonials3.star = document.getElementById("ratio3").value;
        testimonials3.message = document.getElementById("message3").value;
        testimonials3.nameSurname = document.getElementById("name3").value;
        testimonials3.info = document.getElementById("info3").value;
        generalData.mainpagetestimonials[2] = testimonials3
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();
    }
    const handleSubmit = async (e) => {
        if (document.getElementById("isActive").value === "true") {
            testimonials.isActive = "Aktif"
        }
        else {
            testimonials.isActive = "Pasif"
        }
        generalData.testimonials[0] = testimonials
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'post',
                url: "https://express.heryerdeingilizce.com/main",
                data: JSON.stringify(generalData),
                headers: headerss
            });

        }
        catch (err) {
            setMsg(err);
        }
        e.preventDefault();

    }



    const getData = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setTestimonials1(res.data['mainpagetestimonials'][0]);
                setTestimonials2(res.data['mainpagetestimonials'][1]);
                setTestimonials3(res.data['mainpagetestimonials'][2]);
                setTestimonials(res.data['testimonials'][0])
                setgeneralData(res.data)
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        getData();
    }, []);



    return (
        <div className="row">
            {msg}
            <h2>{t('testimonials')}</h2>
            <form onSubmit={testimonialsHandle}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">{t('testimonialsTitle')}</label>
                        <textarea class="form-control" id="title" defaultValue={testimonials.title} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="subtitle" class="form-label">{t('testimonialssubTitle')}</label>
                        <textarea class="form-control" id="subtitle" defaultValue={testimonials.subTitle} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="text" class="form-label">{t('testimonialsText')}</label>
                        <textarea class="form-control" id="text" defaultValue={testimonials.text} />
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
                <br />
            </form>
            <br />
            <hr /><br />
            <h6>{t('currentStatus')} : {testimonials.isActive}</h6>
            <form onSubmit={handleSubmit} >


                <div class="mb-3">
                    <label for="isActive" class="form-label">{t('isActive')}</label>

                    {testimonials.isActive === "Aktif" ? (
                        <select id="isActive" class="form-select" required >
                            <option key="1" value="true" selected>{t('active')}</option>
                            <option key="0" value="false" >{t('passive')}</option>
                        </select>
                    ) : (
                        <select id="isActive" class="form-select" required >
                            <option key="0" value="false" selected>{t('passive')}</option>
                            <option key="1" value="true" >{t('active')}</option>
                        </select>
                    )}
                </div>



                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
                <br />
            </form>
            <br /><hr />
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%", padding: "1%" }}>
                    <h3>Ana Sayfa Yorum 1</h3>
                    <form onSubmit={test1} >
                        <div class="mb-3">
                            <label for="ratio1" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio1" defaultValue={testimonials1.star} />
                        </div>
                        <div class="mb-3">
                            <label for="message1" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message1" defaultValue={testimonials1.message} />
                        </div>
                        <div class="mb-3">
                            <label for="name1" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name1" defaultValue={testimonials1.nameSurname} />
                        </div>
                        <div class="mb-3">
                            <label for="info1" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info1" defaultValue={testimonials1.info} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%", padding: "1%" }}>
                    <h3>Ana Sayfa Yorum 2</h3>
                    <form onSubmit={test2} >
                        <div class="mb-3">
                            <label for="ratio2" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio2" defaultValue={testimonials2.star} />
                        </div>
                        <div class="mb-3">
                            <label for="message2" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message2" defaultValue={testimonials2.message} />
                        </div>
                        <div class="mb-3">
                            <label for="name2" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name2" defaultValue={testimonials2.nameSurname} />
                        </div>
                        <div class="mb-3">
                            <label for="info2" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info2" defaultValue={testimonials2.info} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%", padding: "1%" }}>
                    <h3>Ana Sayfa Yorum 3</h3>
                    <form onSubmit={test3} >
                        <div class="mb-3">
                            <label for="ratio3" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio3" defaultValue={testimonials3.star} />
                        </div>
                        <div class="mb-3">
                            <label for="message3" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message3" defaultValue={testimonials3.message} />
                        </div>
                        <div class="mb-3">
                            <label for="name3" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name3" defaultValue={testimonials3.nameSurname} />
                        </div>
                        <div class="mb-3">
                            <label for="info3" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info3" defaultValue={testimonials3.info} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;