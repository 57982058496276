import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

export const Slider = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);

    const [slider1, setSlider1] = useState([]);
    const [slider2, setSlider2] = useState([]);
    const [slider3, setSlider3] = useState([]);
    const [slider4, setSlider4] = useState([]);
    const [slider5, setSlider5] = useState([]);





    const sliderImage1 = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("yüklendi")

                const dataaa = {
                    slider1: "https://api.heryerdeingilizce.com/" + res.data['data']['path'],
                    slider2: slider2,
                    slider3: slider3,
                    slider4: slider4,
                    slider5: slider5,
                }
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Access-Control-Allow-Credentials': 'false',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                    axios({
                        method: 'put',
                        url: "https://express.heryerdeingilizce.com/postsliders",
                        data: dataaa,
                        headers: headerss
                    }).then(response => {
                        if (response.status === 200) {
                            alert("güncellendi")
                            setSlider1("")
                            setSlider2("")
                            setSlider3("")
                            setSlider4("")
                            setSlider5("")
                            window.location.reload();

                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }

            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    const sliderImage2 = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("yüklendi")

                const dataaa = {
                    slider1: slider1,
                    slider2: "https://api.heryerdeingilizce.com/" + res.data['data']['path'],
                    slider3: slider3,
                    slider4: slider4,
                    slider5: slider5,
                }
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Access-Control-Allow-Credentials': 'false',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                    axios({
                        method: 'put',
                        url: "https://express.heryerdeingilizce.com/postsliders",
                        data: dataaa,
                        headers: headerss
                    }).then(response => {
                        if (response.status === 200) {
                            alert("güncellendi")
                            setSlider1("")
                            setSlider2("")
                            setSlider3("")
                            setSlider4("")
                            setSlider5("")
                            window.location.reload();

                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }

            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    const sliderImage3 = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("yüklendi")

                const dataaa = {
                    slider1: slider1,
                    slider2: slider2,
                    slider3: "https://api.heryerdeingilizce.com/" + res.data['data']['path'],
                    slider4: slider4,
                    slider5: slider5,
                }
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Access-Control-Allow-Credentials': 'false',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                    axios({
                        method: 'put',
                        url: "https://express.heryerdeingilizce.com/postsliders",
                        data: dataaa,
                        headers: headerss
                    }).then(response => {
                        if (response.status === 200) {
                            alert("güncellendi")
                            setSlider1("")
                            setSlider2("")
                            setSlider3("")
                            setSlider4("")
                            setSlider5("")
                            window.location.reload();

                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }

            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    const sliderImage4 = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("yüklendi")
                const dataaa = {
                    slider1: slider1,
                    slider2: slider2,
                    slider3: slider3,
                    slider4: "https://api.heryerdeingilizce.com/" + res.data['data']['path'],
                    slider5: slider5,
                }
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Access-Control-Allow-Credentials': 'false',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                    axios({
                        method: 'put',
                        url: "https://express.heryerdeingilizce.com/postsliders",
                        data: dataaa,
                        headers: headerss
                    }).then(response => {
                        if (response.status === 200) {
                            alert("güncellendi")
                            setSlider1("")
                            setSlider2("")
                            setSlider3("")
                            setSlider4("")
                            setSlider5("")
                            window.location.reload();

                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }

            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }
    const sliderImage5 = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("yüklendi")

                const dataaa = {
                    slider1: slider1,
                    slider2: slider2,
                    slider3: slider3,
                    slider4: slider4,
                    slider5: "https://api.heryerdeingilizce.com/" + res.data['data']['path'],
                }
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Access-Control-Allow-Credentials': 'false',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                    axios({
                        method: 'put',
                        url: "https://express.heryerdeingilizce.com/postsliders",
                        data: dataaa,
                        headers: headerss
                    }).then(response => {
                        if (response.status === 200) {
                            alert("güncellendi")
                            setSlider1("")
                            setSlider2("")
                            setSlider3("")
                            setSlider4("")
                            setSlider5("")
                            window.location.reload();

                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }

            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }

    }

    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getsliders",
                headers: headerss
            }).then(res => {
                setSlider1(res.data[0]['slider1'])
                setSlider2(res.data[0]['slider2'])
                setSlider3(res.data[0]['slider3'])
                setSlider4(res.data[0]['slider4'])
                setSlider5(res.data[0]['slider5'])
            }
            );

        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    useEffect(() => {
        getData();
    }, []);


    return (
        <div className="row">
            {msg}
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}


            <h2>Mevcut Görseller Listesi</h2>
            {!loading && (

                <div className='row' >
                    <h4>Yüklenecek olan resimler 1920*1080 boyutlarında olmalıdır.</h4>
                    <hr />
                    <div className='col'>
                        <h6>1. Resim</h6><br />
                        <img src={slider1} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage1}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={sliderImage1} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>2. Resim</h6><br />
                        <img src={slider2} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage2}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={sliderImage2} id="slider2up" name="slider2up" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>3. Resim</h6><br />
                        <img src={slider3} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage3}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={sliderImage3} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>4. Resim</h6><br />
                        <img src={slider4} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage4}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={sliderImage4} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>5. Resim</h6><br />
                        <img src={slider5} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage5}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={sliderImage5} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}
export default Slider;