import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";

function GetContact() {
    const [data, setData] = useState([]);

    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);



    function changeActivity(event) {
        let isactive = ""
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getcontactbyid/" + event.currentTarget.id,
                headers: headerss
            }).then(response => {
                console.log(response.data[0])
                isactive = response.data[0].isactive
                let newActivity = 1;
                if (isactive == 1) {
                    newActivity = 0;
                }
                else {
                    newActivity = 1;
                }
                const postData = {
                    namesurname: response.data[0].namesurname,
                    message: response.data[0].message,
                    email: response.data[0].email,
                    isactive: newActivity
                }
                const headerss = {
                    "accept": "*/*",
                    'Access-Control-Allow-Credentials': 'false',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
                axios({
                    method: 'put',
                    url: "https://express.heryerdeingilizce.com/updatecontact/" + response.data[0].id,
                    data: postData,
                    headers: headerss
                }).then(response => {
                    if (response.status === 200) {
                        alert(response.data)
                        window.location.reload();
                    }
                });
            });
        }
        catch (err) {
            setMsg(err);
        }




    }


    const getData = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getcontacts",
                headers: headerss
            }).then(res => {
                setData(res.data)
            }
            );
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <div style={{ backgroundColor: "white" }}>
            {msg}
            <div style={{ textAlign: "center" }}>
                <h1>{t("getcontacts")}</h1>
            </div>
            <br />
            <h3>Aktif Talepler</h3>

            <div style={{ overflowX: "auto", border: "1px solid black" }}>
                <table class="table table-bordered">
                    <thead>
                        <tr>

                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col">{t('isActive')}</th>
                            <th scope="col">{t('changeActivity')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((dt) => {
                            if (dt.isactive === 1) {
                                return (
                                    <tr key={dt.id}>
                                        <td>{dt.namesurname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.message}</td>
                                        <td>{dt.isactive ? t('active') : t('passive')}</td>
                                        <td>
                                            <button className="btn btn-success" id={dt.id} onClick={changeActivity}>
                                                {t('change')}
                                            </button>
                                        </td>

                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </div>
            <hr />
            <h3>Pasif Talepler</h3>
            <div style={{ overflowX: "auto", border: "1px solid black" }}>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col">{t('isActive')}</th>
                            <th scope="col">{t('changeActivity')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((dt) => {
                            if (dt.isactive === 0) {
                                return (
                                    <tr key={dt.id}>
                                        <td>{dt.namesurname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.message}</td>
                                        <td>{dt.isactive ? t('active') : t('passive')}</td>
                                        <td>
                                            <button className="btn btn-success" id={dt.id} onClick={changeActivity}>
                                                {t('change')}
                                            </button>
                                        </td>

                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </div>
            {/* <div className="container-fluid">
                <Datatable data={data} columns={columns} tableBaslik={t("courseList")} />
            </div> */}
        </div>
    );
};
export default GetContact;
