import React from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BrowserSidebarAdmin = (props) => {
    const { t } = useTranslation()
    return (
        <ul className="list-unstyled components ">
            <br />

            <li id="homepagesettings">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#homepagesettingss"
                        aria-controls="homepagesettingss"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-money-check-alt" />
                        </b>
                        <b className="forsidebar">&nbsp; {t('homepagesettings')}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="homepagesettingss">
                        <li>
                            <a href="/dashboard/admin/generalsettings">
                                <b className="forsidebar">
                                    {t('generalsettings')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/slider">
                                <b className="forsidebar">
                                    {t('slidersettings')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/corefeatures">
                                <b className="forsidebar">
                                    {t('corefeaturessettings')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/aboutus">
                                <b className="forsidebar">
                                    {t('aboutussettings')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/systemusage">
                                <b className="forsidebar">
                                    {t('systemusage')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/testimonials">
                                <b className="forsidebar">
                                    {t('testimonials')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/footer">

                                <b className="forsidebar">
                                    {t('footersettings')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/contactus">

                                <b className="forsidebar">
                                    {t('Contactussection')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/mainpackages">
                                <b className="forsidebar">
                                    {t('mainpackages')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/kvkk">
                                <b className="forsidebar">
                                    Kvkk & Sözleşmeler
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="userreviews">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#reviewsettings"
                        aria-controls="reviewsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-money-check-alt" />
                        </b>
                        <b className="forsidebar">
                            &nbsp;
                            {t("reviewsettings")}
                        </b>
                    </a>
                    <ul class="collapse list-unstyled" id="reviewsettings">
                        <li>
                            <a href="/dashboard/admin/createreview">
                                <b className="forsidebar">
                                    {t("createreview")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/reviewlist">
                                <b className="forsidebar">
                                    {t("reviewlist")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/getcontacts">
                                <b className="forsidebar">
                                    {t("getcontacts")}
                                </b>
                            </a>
                        </li>


                    </ul>
                </div>
            </li>
            <br />
            <li id="financialadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#financialsettings"
                        aria-controls="questionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-money-check-alt" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("financialSettings")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="financialsettings">
                        <li>
                            <a href="/dashboard/admin/bankaccount">

                                <b className="forsidebar">
                                    {t("bankAccounts")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/packageprice">
                                <b className="forsidebar">
                                    {t("packagesAndPrices")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/packagelist">
                                <b className="forsidebar">
                                    {t("packagesList")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/payment">

                                <b className="forsidebar">
                                    {t("paymentRequests&newRegister")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="coupenadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#admincoupensettings"
                        aria-controls="admincoupensettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-money-check-alt" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("coupensettings")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="admincoupensettings">
                        <li>
                            <a href="/dashboard/admin/coupenlist">

                                <b className="forsidebar">
                                    {t("coupenlist")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/createcoupen">

                                <b className="forsidebar">
                                    {t("createCoupen")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="useradmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#individualusersettings"
                        aria-controls="questionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("individualUserSettings")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="individualusersettings">

                        {/* <li>
                                    <a href="/dashboard/admin/usertypelist">
                                        <b className="forsidebar">
                                            {t("userTypesList")}
                                        </b>
                                    </a>
                                </li> */}

                        <li>
                            <a href="/dashboard/admin/userlist">

                                <b className="forsidebar">
                                    {t("userList")}
                                </b>
                            </a>
                        </li>

                    </ul>
                </div>
            </li>
            <br />
            <li id="corporateadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#corporateusersettings"
                        aria-controls="questionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("corporateUserSettings")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="corporateusersettings">
                        <li>
                            <a href="/dashboard/admin/corporateuserlist">

                                <b className="forsidebar">
                                    {t("corporateuserList")}
                                </b>
                            </a>
                        </li>


                        <li>
                            <a href="/dashboard/admin/schoollist">

                                <b className="forsidebar">
                                    {t("schoolList")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/createschool">

                                <b className="forsidebar">
                                    {t("createSchool")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="admintickets">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#adminticketsettings"
                        aria-controls="questionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("tickets")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="adminticketsettings">
                        <li>
                            <a href="/dashboard/admin/searchticket">

                                <b className="forsidebar">
                                    {t("searchticket")}
                                </b>
                            </a>
                        </li>

                    </ul>
                </div>
            </li>
            <br />
            <li id="moderator">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#moderatorsettings"
                        aria-controls="moderatorsettingssss"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">&nbsp;{t("websiteuser")}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="moderatorsettings">
                        <li>
                            <a href="/dashboard/admin/createmoderator">

                                <b className="forsidebar">
                                    {t("createmoderator")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/moderatorlist">

                                <b className="forsidebar">
                                    {t("moderatorlist")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <hr />
            <li id="sectionadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#sectionsettings"
                        aria-controls="sectionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-university" />
                        </b>
                        <b className="forsidebar">&nbsp;{t('sectionSettings')}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="sectionsettings">
                        <li>
                            <a href="/dashboard/admin/createsection">
                                <b className="forsidebar">
                                    {t('createSection')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/sectionlist">
                                <b className="forsidebar">
                                    {t('sectionList')}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="courseadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#coursesettings"
                        aria-controls="coursesettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-university" />
                        </b>
                        <b className="forsidebar">&nbsp;{t('courseSettings')}</b>
                    </a>
                    <ul class="collapse list-unstyled" id="coursesettings">
                        <li>
                            <a href="/dashboard/admin/createcourse">
                                <b className="forsidebar">
                                    {t('createCourse')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/courselist">
                                <b className="forsidebar">
                                    {t('courseList')}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="subjectadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#subjectsettings"
                        aria-controls="subjectsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-paperclip" />
                        </b>

                        <b className="forsidebar">&nbsp;{t("subjectSettings")}</b>

                    </a>
                    <ul class="collapse list-unstyled" id="subjectsettings">
                        <li>
                            <a href="/dashboard/admin/createsubject">

                                <b className="forsidebar">
                                    {t("createSubject")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/subjectlist">

                                <b className="forsidebar">
                                    {t("subjectList")}
                                </b>
                            </a>
                        </li>

                    </ul>
                </div>
            </li>
            <br />
            <li id="questionadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#questionsettings"
                        aria-controls="questionsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-question" />                                    </b>

                        <b className="forsidebar">&nbsp;{t("questionSettings")}</b>

                    </a>
                    <ul class="collapse list-unstyled" id="questionsettings">
                        <li>
                            <a href="/dashboard/admin/createquestion">

                                <b className="forsidebar">
                                    {t("createQuestion")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/questionlist">

                                <b className="forsidebar">
                                    {t("questionList")}
                                </b>
                            </a>
                        </li>

                    </ul>
                </div>
            </li>
            <br />
            <li id="tagadmin">
                <div>
                    <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#tagsettings"
                        aria-controls="tagsettings"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-question" />                                    </b>

                        <b className="forsidebar">&nbsp;{t("tagSettings")}</b>

                    </a>
                    <ul class="collapse list-unstyled" id="tagsettings">
                        <li>
                            <a href="/dashboard/admin/createtag">
                                <b className="forsidebar">
                                    {t("createTag")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/admin/taglist">

                                <b className="forsidebar">
                                    {t("tagList")}
                                </b>
                            </a>
                        </li>

                    </ul>
                </div>
            </li>
            <br />
        </ul>
    );
};

export default BrowserSidebarAdmin;