import { useAuth } from "../../context/AuthContext"
import { Link } from "react-router-dom"
import './login.css'
import './style.css'
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import axios from "axios";


export default function Register() {
    const { t } = useTranslation();
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();
    if (user) {
        //navigate("/dashboard");
        window.location.href = "/dashboard";
    }
    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false'
            }
            let url = "https://api.heryerdeingilizce.com/api/User/RegisterInvidual";
            let sectionId = 1;
            let isTrial = true;
            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: document.getElementById("email").value,
                    password: document.getElementById("password").value,
                    fullName: document.getElementById("nameandsurname").value,
                    sectionId: sectionId,
                    isTrial: isTrial
                },
                headers: headerss
            }).then(res => {

                if (res.status === 200) {
                    if (res.data['success']) {
                        window.location.href = "/auth/verifyemail/" + document.getElementById("email").value;

                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    return (
        <div class="container-fluid h-custom">

            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                        class="img-fluid" alt="Sample" />
                </div>

                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1" id="registerForm" >
                    <form onSubmit={handleRegister}>
                        <div class="divider d-flex align-items-center my-4">
                            <h2 class="text-center fw-bold mx-3 mb-0">
                                {t("register")}
                            </h2>
                        </div>
                        {msg}
                        <div class="form-outline mb-3">
                            <label class="form-label" for="nameandsurname">{t("namesurname")}</label>
                            <textarea type="name" id="nameandsurname" class="form-control form-control-lg"
                                placeholder={t("enternamesurname")} required />
                        </div>
                        <div class="form-outline mb-4">
                            <label class="form-label" for="email">{t("emailaddress")}</label>
                            <input type="email" id="email" class="form-control form-control-lg"
                                placeholder={t("validemail")} required />
                        </div>
                        <div class="form-outline mb-3">
                            <label class="form-label" for="password">{t("enterpassword")}</label>
                            <input type="password" id="password" class="form-control form-control-lg"
                                placeholder={t("enterpassword")} required />


                        </div>
                        <div class="form-outline mb-3">
                            <label class="form-label" for="coupencode">{t("coupencode")}</label>
                            <textarea id="coupencode" class="form-control form-control-lg"
                                placeholder="Kod yoksa boş bırakınız." />
                        </div>
                        <div class="form-outline mb-3">
                            <input type="checkbox" id="kvkkmetin" required />
                            <label class="form-label" for="kvkkmetin">
                                <strong><Link to="/kvkkbilgilendirme" target="_blank">
                                    <u> &nbsp;KVKK Kapsamında</u>
                                </Link>&nbsp;Ticari ileti almayı kabul ediyorum</strong>
                            </label>
                            <br />
                            <input type="checkbox" id="sozlesme" required />
                            <label class="form-label" for="sozlesme" required> <strong>&nbsp;
                                <Link to="/kullanicisozlesmesi" target="_blank">
                                    <u> Üyelik Sözleşmesini</u>
                                </Link>&nbsp;Kabul ediyorum
                            </strong></label>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-success btn-lg" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}> {t("register")}</button>

                        </div>
                    </form>

                    <br /><br />


                    <h3>{t("alreadyhaveaccount")}&nbsp;
                        <Link to="/auth/login" class="link-info">
                            {t("login")}
                        </Link>
                    </h3>

                </div>
            </div>

        </div>
    )
}