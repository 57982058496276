import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import axios from "axios";


export const AdminEditSubject = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [courses, setCourses] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [colourcode, setColourcode] = useState(null);
    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("Yüklendi!")
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path'];
                setImagePath(url);
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    id: data.id,
                    isDeleted: data.isDeleted,
                    subjectName: document.getElementById("subjectName").value,
                    subjectDesc: document.getElementById("subjectDesc").value,
                    sortId: document.getElementById("sortId").value,
                    isActive: document.getElementById("isActive").value,
                    courseId: document.getElementById("courseId").value,
                    featuredImage: data.featuredImage
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handleImage = async (e) => {
        if (!imagePath) {
            setImagePath("https://api.heryerdeingilizce.com/photo/91ebd6aa-6a51-4dcc-ad27-5633dbddd6e6.png")
        }
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    id: data.id,
                    isDeleted: data.isDeleted,
                    courseId: data.courseId,
                    subjectDesc: data.subjectDesc,
                    sortId: data.sortId,
                    isActive: data.isActive,
                    subjectName: data.subjectName,
                    featuredImage: imagePath
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }


    const selectcolour = async (e) => {
        e.preventDefault();
        const dataaa = {
            colourcode: document.getElementById("favcolor").value,
            info: ".",
        }
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            axios({
                method: 'put',
                url: "https://express.heryerdeingilizce.com/updatesubjectcolour/" + params["id"],
                data: dataaa,
                headers: headerss
            }).then(response => {
                if (response.status === 200) {
                    alert("güncellendi")
                    window.location.reload();
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }


    const checkcolour = async (e) => {
        //
        try {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/getsubjectcolourbyid/" + params["id"],
                headers: headerss
            }).then(response => {
                if (response.status === 200) {
                    if (response.data.length === 0) {
                        const dataaa = {
                            subjectid: params["id"],
                            colourcode: "#000000",
                            info: ".",
                        }
                        const headerss = {
                            "accept": "*/*",
                            'Access-Control-Allow-Credentials': 'false',
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'
                        }
                        axios({
                            method: 'post',
                            url: "https://express.heryerdeingilizce.com/postsubjectcolour",
                            data: dataaa,
                            headers: headerss
                        }).then(response => {
                            if (response.status === 200) {
                                setColourcode("#000000")
                            }
                        });
                    }
                    else {
                        setColourcode(response.data[0].colourcode)
                    }
                }

            });
        }
        catch (err) {
            setMsg(err);
        }
    }
    const fetchsections = async () => {
        setLoadingg(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCourses";

            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    for (let i = 0; i < res.data['data'].length; i++) {
                        courses.push({
                            "id": res.data['data'][i]['id'],
                            "courseName": res.data['data'][i]['courseName'],
                        })
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingg(false);
    }
    const fetchdata = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetById";

            await axios({
                method: 'GET',
                url: url,
                params: {
                    id: params["id"]
                },
                headers: headerss
            }).then(res => {

                if (res.status === 200) {

                    if (res.data['success']) {
                        setData(res.data['data']);
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://www.paytr.com/js/iframeResizer.min.js";
        script.async = true;

        document.body.appendChild(script);







        fetchsections();
        if (loadingg) {
            fetchdata();
        }
        checkcolour();
    }, [])




    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            {msg}
            <div style={{ textAlign: "center" }}>
                <h1>{t("editSubject")}</h1>
            </div>
            <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="courseId" class="form-label">{t("selectCourse")}</label>
                    <select id="courseId" class="form-select" aria-label="Default select example">

                        {courses ? (
                            courses && courses.map((dt) => {
                                if (data.courseId === dt.id) {
                                    return (
                                        <option key={dt.id} value={dt.id} selected>{dt.courseName}</option>
                                    );
                                }
                                else {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.courseName}</option>
                                    );
                                }
                            })
                        ) : (
                            <p>Please Reflesh page</p>
                        )
                        }
                    </select>
                </div>


                <div class="mb-3">
                    <label for="subjectName" class="form-label">{t("subjectName")}</label>
                    <textarea class="form-control" id="subjectName" defaultValue={data.subjectName} required />
                </div>

                <div class="mb-3">
                    <label for="subjectDesc" class="form-label">{t("subjectDescription")}</label>
                    <textarea class="form-control" id="subjectDesc" defaultValue={data.subjectDesc} required />
                </div>
                <div class="mb-3">
                    <label for="sortId" class="form-label">{t("sortid")}</label>
                    <textarea class="form-control" id="sortId" defaultValue={data.sortId} required />
                </div>
                <div class="mb-3">
                    <label for="isActive" class="form-label">{t('isActive')}</label>

                    {data.isActive ? (
                        <select id="isActive" class="form-select" required >
                            <option key="1" value="true" selected>{t('active')}</option>
                            <option key="0" value="false" >{t('passive')}</option>
                        </select>

                    ) : (
                        <select id="isActive" class="form-select" required >
                            <option key="0" value="false" selected>{t('passive')}</option>
                            <option key="1" value="true" >{t('active')}</option>
                        </select>
                    )}
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>

            </form>
            <br />
            <hr />
            <br />
            <form onSubmit={handleImage}>
                <h4>{t('courseImage')}</h4>
                <br />
                <div className='row'>
                    <div className='col-md-9'>
                        <img src={data.featuredImage} alt='courseLogo' />
                    </div>
                    <div className='col-md-3'>
                        <div class="mb-3">
                            <label for="schoollogo" class="form-label">{t("image")}</label>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" />
                        </div>
                    </div>

                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>
            <hr />
            <br />
            <h4>Konu Arka plan rengi</h4>
            <span>görünmüyorsa sayfayı yenileyiniz.</span><br />
            Renk Kodu : {colourcode}  <br />
            Mevcut Renk Seçimi:
            <input type="color" id="favcolor" name="favcolor" value={colourcode} />

            <form onSubmit={selectcolour}>
                <label for="favcolor">Lütfen rengi seçiniz</label>
                <input type="color" id="favcolor" name="favcolor" value="#ff0000"></input>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>



        </div>
    );
};
export default AdminEditSubject;
