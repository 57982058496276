import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Pagination = (props) => {
    const params = useParams();

    const handleClick = event => {

        window.location.href = event.target.id

    };
    useEffect(() => {
        var intvalue = (Math.floor(props.pagination.length / 10)) + 1;
    }, []);
    return (

        <div class=" justify-content-center" style={{ alignContent: "center", alignItems: "center", justifyItems: "center" }}>
            <div>
                <button style={{ backgroundColor: "LawnGreen", margin: "1px" }} class="btn page-item" aria-current="page">
                    <a style={{ backgroundColor: "LawnGreen", fontSize: "12px", color: "white" }} class="page-link" >1</a>
                </button>
                Doğru çözülmüş soru
                <button style={{ backgroundColor: "Crimson", margin: "1px" }} class="btn page-item" aria-current="page">
                    <a style={{ backgroundColor: "Crimson", fontSize: "12px", color: "white" }} class="page-link" >2</a>
                </button>
                Hatalı çözülmüş soru
                <button style={{ backgroundColor: "gray", margin: "1px" }} class="btn page-item" aria-current="page">
                    <a style={{ backgroundColor: "gray", fontSize: "12px", color: "white" }} class="page-link">3</a>
                </button>
                Henüz çözülmüş soru
            </div>
            <div>
                {props.pagination && props.pagination.map((dt) => {

                    if (dt.isActive) {
                        if (dt.answered) {
                            if (dt.answerCorrect) {
                                return (

                                    <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "LawnGreen", margin: "1px" }} class="btn page-item " aria-current="page">
                                        <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "LawnGreen", fontSize: "18px", color: "white" }} class="page-link" >{dt.id}</a>
                                    </button>
                                )
                            }
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "Crimson", margin: "1px" }} class="btn page-item" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "Crimson", fontSize: "18px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                        else {
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray", margin: "1px" }} class="btn page-item" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray", fontSize: "18px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                    }
                    else {
                        if (dt.answered) {
                            if (dt.answerCorrect) {
                                return (
                                    <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "LawnGreen", margin: "1px" }} class="btn page-item" aria-current="page">
                                        <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "LawnGreen", fontSize: "12px", color: "white" }} class="page-link" >{dt.id}</a>
                                    </button>

                                )
                            }
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "Crimson", margin: "1px" }} class="btn page-item" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "Crimson", fontSize: "12px", color: "white" }} class="page-link" >{dt.id}</a>
                                </button>
                            )
                        }

                        else {
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray", margin: "1px" }} class="btn page-item" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray", fontSize: "12px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                    }
                })}


            </div>
        </div>


    );
};

export default Pagination;

