import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import axios from "axios";

const MobileNavbarStudent = (props) => {
    const { t } = useTranslation()
    const { user } = useAuth();
    const [logo, setLogo] = useState('');
    useEffect(() => {
        (async () => {
            const headerss = {
                "accept": "*/*",
                'Access-Control-Allow-Credentials': 'false',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
            await axios({
                method: 'get',
                url: "https://express.heryerdeingilizce.com/main",
                headers: headerss
            }).then(res => {
                setLogo(res.data['genel'][0]);
            }
            );

        })()
    }, [])
    return (
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid" style={{ backgroundColor: "white" }}>
                <a className="navbar-brand" href="/">
                    <img src={logo['logoUrl']} alt="logo" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <a href='/dashboard/user/course'  >
                            <b>  {t('course')}</b>
                        </a>
                        <hr />
                        <a href='/dashboard/usergeneral'  >
                            <b> {t('generalreport')}</b>
                        </a>
                        <hr />
                        <a href='/dashboard/userskill'  >
                            <b> {t('skills')}</b>
                        </a>
                    </ul>
                    <hr />
                    <form class="d-flex" style={{ padding: "3%" }}>
                        <a href="/profile">
                            <i
                                class="fa fa-user-circle"
                                style={{ fontSize: 30 }}
                                aria-hidden="true"
                            >
                            </i>
                            &nbsp;
                            <span>

                                <strong>{user.name}</strong>
                                <br />
                                {user.role}
                            </span>
                        </a>
                    </form>
                </div>
            </div>
        </nav >
    );
};

export default MobileNavbarStudent;